const TextSizeIcon = ({ className = 'fill-bgPrimary transition-none' }: { className?: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    className={`h-[1em] w-[1em] ${className}`}
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M4 1H14V3H4V1Z" />
    <path d="M8 1H10V13H8V1Z" />
    <path d="M0 6H6V8H0V6Z" />
    <path d="M2 6H4V13H2V6Z" />
  </svg>
);

export default TextSizeIcon;
