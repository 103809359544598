const DownChevron = ({ className = 'stroke-bgSecondary' }: { className?: string }) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    className={`h-[1em] w-[1em] ${className}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 6.75L8 11.25L12.5 6.75" strokeMiterlimit="10" strokeLinecap="square" />
  </svg>
);

export default DownChevron;
