const ContrastIcon = ({ className = 'fill-bgPrimary transition-none' }: { className?: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    className={`h-[1em] w-[1em] ${className}`}
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2C9.76142 2 12 4.23858 12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2ZM14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7Z"
      />
      <path d="M7 13C10.3138 13 13 10.3138 13 7C13 3.68615 10.3138 1 7 1V13Z" />
    </g>
  </svg>
);

export default ContrastIcon;
