import { cva, type VariantProps } from 'class-variance-authority';

export type ButtonVariantProps = VariantProps<typeof navButtonStyles> & {
  href: string | null | undefined;
  children: React.ReactNode;
  onClick?: () => void;
};

// Define base styles and variants
const navButtonStyles = cva(
  'rounded-full h-8 px-3 inline-flex items-center font-medium text-sm', // Base styles
  {
    variants: {
      color: {
        blue: 'bg-sky-300 text-green-600',
        lightBlue: 'bg-sky-200 text-green-600 hover:bg-sky-200',
        darkGreen: 'bg-green-600 text-primary hover:bg-green-700',

        // new colours
        darkBlue: 'bg-bgPrimary text-primary hover:border-white border border-midnight-blue-500',
        midnightBlue: 'bg-bgPrimary text-primary hover:bg-midnight-blue-600',
        turquoise: 'bg-accent text-tBase hover:bg-accent',
        white: 'bg-primary text-tBase hover:bg-primary',
      },
    },
    defaultVariants: {
      color: 'lightBlue', // Default size if none is provided
    },
  },
);

const NavButton = ({ children, color, href, ...props }: ButtonVariantProps) => {
  return (
    <a href={href || '#'} {...props} className={navButtonStyles({ color })}>
      {children}
    </a>
  );
};

export default NavButton;
