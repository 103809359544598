// instagram.tsx

export const InstagramIcon: React.FC = ({ className = 'fill-white' }: { className?: string }) => (
  <svg
    className={`h-[1em] w-[1em] ${className}`}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3748 3.35434C17.5342 3.3578 18.6451 3.81988 19.4648 4.63966C20.2846 5.45944 20.7467 6.57031 20.7502 7.72965V16.4793C20.7467 17.6387 20.2846 18.7495 19.4648 19.5693C18.6451 20.3891 17.5342 20.8512 16.3748 20.8546H7.62516C6.46582 20.8512 5.35495 20.3891 4.53517 19.5693C3.71539 18.7495 3.25331 17.6387 3.24984 16.4793V7.72965C3.25331 6.57031 3.71539 5.45944 4.53517 4.63966C5.35495 3.81988 6.46582 3.3578 7.62516 3.35434H16.3748ZM16.3748 1.60449H7.62516C4.25625 1.60449 1.5 4.36074 1.5 7.72965V16.4793C1.5 19.8482 4.25625 22.6045 7.62516 22.6045H16.3748C19.7437 22.6045 22.5 19.8482 22.5 16.4793V7.72965C22.5 4.36074 19.7437 1.60449 16.3748 1.60449Z" />
    <path d="M17.6873 7.72949C17.4278 7.72949 17.174 7.65252 16.9582 7.5083C16.7423 7.36408 16.5741 7.15909 16.4748 6.91926C16.3754 6.67944 16.3494 6.41554 16.4001 6.16094C16.4507 5.90634 16.5757 5.67247 16.7593 5.48891C16.9428 5.30536 17.1767 5.18036 17.4313 5.12971C17.6859 5.07907 17.9498 5.10506 18.1896 5.2044C18.4294 5.30374 18.6344 5.47197 18.7786 5.68781C18.9229 5.90365 18.9998 6.1574 18.9998 6.41699C19.0002 6.58946 18.9665 6.7603 18.9007 6.9197C18.8349 7.07911 18.7382 7.22395 18.6163 7.3459C18.4943 7.46785 18.3495 7.56451 18.1901 7.63034C18.0306 7.69617 17.8598 7.72986 17.6873 7.72949ZM12 8.60418C12.6923 8.60418 13.369 8.80946 13.9446 9.19406C14.5202 9.57867 14.9688 10.1253 15.2337 10.7649C15.4986 11.4045 15.568 12.1082 15.4329 12.7872C15.2978 13.4661 14.9645 14.0898 14.475 14.5793C13.9855 15.0688 13.3618 15.4022 12.6828 15.5372C12.0039 15.6723 11.3001 15.603 10.6606 15.3381C10.021 15.0731 9.47433 14.6245 9.08973 14.0489C8.70513 13.4733 8.49985 12.7966 8.49985 12.1043C8.50084 11.1763 8.86992 10.2866 9.52611 9.63045C10.1823 8.97426 11.072 8.60517 12 8.60418ZM12 6.85434C10.9617 6.85434 9.94662 7.16224 9.08326 7.73912C8.2199 8.316 7.54699 9.13594 7.14963 10.0952C6.75227 11.0546 6.64831 12.1102 6.85088 13.1286C7.05345 14.147 7.55347 15.0824 8.28769 15.8166C9.02192 16.5509 9.95738 17.0509 10.9758 17.2535C11.9942 17.456 13.0498 17.3521 14.0091 16.9547C14.9684 16.5573 15.7883 15.8844 16.3652 15.0211C16.9421 14.1577 17.25 13.1427 17.25 12.1043C17.25 10.7119 16.6969 9.37659 15.7123 8.39203C14.7277 7.40746 13.3924 6.85434 12 6.85434Z" />
  </svg>
);
