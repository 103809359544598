// youtube.tsx

export const YouTubeIcon: React.FC = ({ className = 'fill-white' }: { className?: string }) => (
  <svg
    className={`h-[1em] w-[1em] ${className}`}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M23.8428 7.07902C23.8428 4.96965 22.2912 3.27277 20.374 3.27277C17.7772 3.15137 15.1287 3.10449 12.4222 3.10449H11.5784C8.87841 3.10449 6.22528 3.15137 3.62841 3.27324C1.71591 3.27324 0.164348 4.97949 0.164348 7.08887C0.04716 8.75715 -0.00252745 10.4259 0.000285051 12.0946C-0.00440245 13.7634 0.0487226 15.4337 0.15966 17.1056C0.15966 19.215 1.71122 20.9259 3.62372 20.9259C6.35185 21.0525 9.15028 21.1087 11.9956 21.104C14.8456 21.1134 17.6362 21.054 20.3675 20.9259C22.2847 20.9259 23.8362 19.215 23.8362 17.1056C23.9487 15.4321 24.0003 13.7634 23.9956 12.09C24.0062 10.4212 23.9553 8.7509 23.8428 7.07902ZM9.70341 16.6931V7.48215L16.5003 12.0853L9.70341 16.6931Z" />
  </svg>
);
