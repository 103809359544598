'use client';

import UpArrowIcon from '../Icons/UpArrowIcon';

const ScrollToTopButton = () => {
  const scrollToTop = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <button
      onClick={scrollToTop}
      className="bg-accent flex h-12 w-12 items-center justify-center rounded-full">
      <UpArrowIcon />
    </button>
  );
};

export default ScrollToTopButton;
