const RightArrow = ({ className = 'stroke-bgSecondary' }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={`h-[1em] w-[1em] ${className}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0625 5.25L21.8125 12L15.0625 18.75M20.875 12H2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default RightArrow;
