const ReadModeIcon = ({ className = 'fill-bgPrimary transition-none' }: { className?: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    className={`h-[1em] w-[1em] ${className}`}
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 0H1V14H13V0ZM3 4H11V2H3V4ZM11 8H3V6H11V8ZM3 12H8V10H3V12Z"
    />
  </svg>
);

export default ReadModeIcon;
